.number-container {
    min-height: 25px;
    max-height: 9vw;
    min-width: 250px;
    max-width: 90vw;
    margin: 50px auto;
}
.number-button {
    height: 100%;
    width: calc(100%/11);
    background: none;
    border: 1px solid gray;

}

@media (max-width: 500px) {
    .number-container {
        min-width: 90vw;
        min-height: 9vw;
    }
}

