.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f0f3fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  color: rgb(50, 62, 168);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand-custom {
  width: 100%;
  text-align: center;
}


.containerImg{
  background-image: url(./img/workshop.jpeg);
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}

.button-container {
  margin-top: 40px;
}

.button {
  background-color: #b9deba;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #cb551e;
}

img {
  max-width: 100%; /* Set the maximum width to 100% of the parent element */
  height: auto; /* Automatically adjust the height to maintain the aspect ratio */
}