.sudoku-board {
    border: 3px solid black;
    margin: 5px auto;
    border-collapse: collapse;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    text-align: center;
    font-size: calc(100%/9 - 1);
    min-width: 250px;
    min-height: 250px;
    max-width: 100vmin;
    max-height: 100vmin;
}

.sudoku-cell {
    border: 1px solid black;
    margin: 0;
    height: calc(100%/9);
    width: calc(100%/9);
}

.sudoku-board .sudoku-row:nth-child(3n) {
    border-bottom: 3px solid black;
}

.sudoku-board .sudoku-cell:nth-child(3n) {
    border-right: 3px solid black;
}




.candidates-grid {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
    text-align: center;


}

.candidates-grid td {
    width: calc(100%/3);
}

.candidates-grid tr {
    height: calc(100%/3);
}


@media (max-width: 500px) {
    .sudoku-board {
        min-height: 94vw;
        min-width: 94vw;
        
        max-height: 94vw;
        max-width: 94vw;
    }


}