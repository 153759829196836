.show-help-container {
    text-align: center;
}

.show-help-button {
    margin-top: 25px;
    margin-bottom: 0;
    background: none;
    border: 2px solid black;
    outline: none;
}

.help-button-container {
    margin: 5px auto 25px;
    min-width: 250px;
    max-width: 90vw;
    text-align: center;
}



.open {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;

    animation-duration: 0.2s;
    -webkit-animation-duration: 0.2s;
}

@keyframes slideDown {
    from {
        clip-path: polygon(40% 0, 60% 0, 60% 10%, 40% 10%);
    } to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@-webkit-keyframes slideDown {
	from {
        -webkit-clip-path: polygon(40% 0, 60% 0, 60% 10%, 40% 10%);
    } to {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

.help-button {
    border: 2px solid rgb(209, 107, 23);
    background: none;
    height: 5vh;
    margin: 2px 2px;
    outline: none;
}

.help-button:active {
    background: rgb(209, 107, 23);;
}

@media (max-width: 500px) {
    .help-button-container {
        min-width: 95vw;
    }
}