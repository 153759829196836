.button-container {
    max-width: 90vw;
    min-width: 250px;
    margin: 0 auto;
}

.difficulty-button {
    width: 30%;
    height: 40px;
    margin: 0 1.5%;
    background: none;
    border: 5px solid #00bccc;
    outline: none;
}

.difficulty-button-active {
    background: #00bccc;
}

@media (max-width: 500px) {
    .button-container {
        min-width: 95vw;
    }
}